var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"position-wrap"},[_c('div',{staticClass:"boothMap"},[_vm._l((_vm.boothDataList),function(item){return _c('div',{key:item.key,class:[
        item.booth_no,
        item.color,
        item.selected ? 'selecteBg' : '',
        item.is_booked ? 'selectedBg' : '',
        item.is_ban?'disabled':''
      ],on:{"click":function($event){return _vm.clickSome(item)}}},[_vm._v(" "+_vm._s(_vm._f("textFormat")(item.booth_no))+" ")])}),_vm._l((_vm.writing),function(item){return _c('div',{key:item.key,staticClass:"arrow-item",class:[item.key]},[_vm._v(" "+_vm._s(_vm._f("priorFormat")(item.text_en,item.text, _vm.LOCALE))+" ")])}),_vm._l((_vm.column),function(item,index){return _c('div',{key:item.key,staticClass:"pillar",class:[item.key]})}),_vm._l((_vm.backgroundText),function(item,index){return _c('div',{key:item.key,class:[item.key]},[_vm._v(" "+_vm._s(item.text)+" ")])})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }