<template>
  <div class="position-wrap">
    <div class="boothMap">
      <div
        v-for="item in boothDataList"
        :key="item.key"
        @click="clickSome(item)"
        :class="[
          item.booth_no,
          item.color,
          item.selected ? 'selecteBg' : '',
          item.is_booked ? 'selectedBg' : '',
          item.is_ban?'disabled':''
        ]"
      >
        {{ item.booth_no | textFormat }}
      </div>
      <div
        class="arrow-item"
        v-for="item in writing"
        :key="item.key"
        :class="[item.key]"
      >
        {{ item.text_en | priorFormat(item.text, LOCALE) }}
      </div>
      <div class="pillar" :class="[item.key]" v-for="(item,index) in column" :key="item.key">
      </div>
      <div :class="[item.key]" v-for="(item,index) in backgroundText" :key="item.key" >
        {{item.text}}
      </div>
    </div>
  </div>
</template>

<script>
import { boothData, writing,column,backgroundText } from "~exp/utils/boothData";
import "~exp/assets/style/booth.less";

export default {
  name: "boothReservation",
  props: {
    //当前已选的
    selected: {
      type: Array,
      default: function() {
        return [];
      },
    },
    activity_id:{
      type:Number|String,
      default:0
    }
  },
  data() {
    return {
      boothDataList: [],
      writing: writing,
      column:column,
      backgroundText:backgroundText
    };
  },
  watch: {
    selected: {
      handler(nv){
        this.boothDataList.forEach((item) => {
          item.selected = false;
        });

        this.boothDataList.forEach((item) => {
          this.selected.forEach((ee) => {
            if (ee.booth_no === item.booth_no) {
              item.selected = true;
            }
          });
        });
        this.$forceUpdate();
      },
      immediate:true

    },
    activity_id(nv){
      console.log(nv)
      if(nv){
        this.getList()
      }
    }
  },
  mounted() {
    // this.getList();
  },
  methods: {
    async getList() {
      let params = {
        activity_id:this.activity_id,
        nopage: 1,
      };
      let res = await this.$store.dispatch("register/boothLists", params);
      if (res.success) {
        let arr = [];
        res.data.forEach((item) => {
          arr = arr.concat(item.data_list);
        });

        arr.forEach((item) => {
          item.selected = false;
          if (item.price_cny === 8800) {
            item.color = "blue";
          } else if (item.price_cny === 9800) {
            item.color = "orange";
          } else if (item.price_cny === 36000) {
            item.color = "deepOrange";
          } else if (item.price_cny === 72000) {
            item.color = "brown";
          } else if (item.price_cny === 0 && item.price_usd === 1500) {
            item.color = "green";
          }
        });
        this.boothDataList = arr;
      }
    },
    clickSome(item) {
      // this.$emit("selectSomeBooth", item);
    },
  },
};
</script>

<style scoped lang="less">
.position-wrap {
  padding-top: 50px;
  padding-bottom: 40px;
}
.boothMap {
  width: 1105px;
  height: 480px;
  position: relative;
  div {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    cursor: pointer;
    background: #fff;
    width: 30px;
    height: 30px;
    border: 1px solid #000;
  }
  //.orange {
  //  background-color: #ffd966;
  //  color: #000;
  //}
  //.blue {
  //  background-color: #305496;
  //  color: #fff;
  //}
  //.green {
  //  background-color: #73f288;
  //  color: #000;
  //}
  //.deepOrange {
  //  background-color: #ed7d31;
  //  color: #fff;
  //}
  //.brown {
  //  background-color: #833c0c;
  //  color: #fff;
  //}
  .selectedBg {
    background: #ffd966 !important;
    color: #000 !important;
  }
  .selecteBg {
    background: #ff5a5e !important;
    color: #000 !important;
  }
  .disabled{
    background: #bfbfbf;
  }
  .arrow-item {
    font-size: 16px;
    font-weight: bold;
  }
  .pillar{
    width: 20px;
    height: 15px;
    background: #000;
    position: absolute;
  }
}
</style>
